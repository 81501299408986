import {useLayoutEffect, useState} from "react";
import {tartunlpApi} from "./variables";
//import ReactGA from "react-ga4";

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

/* TODO: Google Analytics function. Uncomment this function and uncomment the line in App.js */
/*export function googleLocation() {
    ReactGA.set({page: window.location.pathname});
    ReactGA.send("pageview");
}*/

export async function translateAsync(text) {
    const settings = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Application': 'livesubs',
        },
        body: JSON.stringify({
            'text': text,
            'tgt': 'en',
            'src': 'et',
        })
    };

    try {
        let response = await fetch(`${tartunlpApi}/translation/v2`, settings);
        return response.json();
    }
    catch (e) {
        return e;
    }
}
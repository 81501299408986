export const wordpressUrl = 'https://tartunlp.ai/wordpress/index.php/wp-json/wp/v2';
export const tartunlpApi = 'https://api.tartunlp.ai'
export const dataProtectionPolicy = '1157';
/* TODO: add your own variables here with the correct WordPress post/page IDs (like the data protection policy one) */
/* THIS IS AN EXAMPLE! */
export const collaboration = '466';
export const mainPageInfo = '';

export const maxChars = 200;

export const ASR_URL = 'wss://api.tartunlp.ai/speech-to-text/ws';
// For local testing
//export const ASR_URL = 'ws://127.0.0.1:8000/'


export function cleanHtml(content) {
    let div = document.createElement('div');
    div.innerHTML = content;

    let scripts = div.querySelectorAll('style, scripts');
    let i = scripts.length;

    while (i--) {
        scripts[i].parentNode.removeChild(scripts[i]);
    }

    return div.innerHTML;
}

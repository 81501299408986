import "./Home.scss"
import Transcription from "../transcription/Transcription";

const Home = () => {
    return (
        <section className={'home section__viewport'}>
            <Transcription />
        </section>
    )
}

export default Home
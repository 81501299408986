import React from 'react';
import './App.scss';
import Header from "./header/Header";
import Home from "./home/Home";
import NotFoundPage from "./not-found-page/NotFoundPage";
import {useTranslation} from "react-i18next";
import {Route, Routes} from "react-router-dom";
import {Helmet} from "react-helmet";

const Main = () => {
    return (
        <Routes>
            <Route exact path={'/'} element={<Home />}/>
            <Route element={<NotFoundPage/>}/>
        </Routes>
    );
};

const App = () => {
    const {t, i18n} = useTranslation();

    return (
        <>
            <Helmet defaultTitle={t('Livesubs')} titleTemplate={`%s | ${t('title of the webpage')}`}>
                <html lang={i18n.language.split("-")[0]} />
                <title lang={i18n.language.split("-")[0]} />
                <meta name="description" content={t('meta description')} />
                <meta property="og:title" content={t('title of the webpage')} />
                <meta name="twitter:title" content={t('title of the webpage')} />
                <meta property="og:description" content={t('meta description')} />
                <meta name="twitter:description" content={t('meta description')} />
            </Helmet>
            <Header />
            <Main />
        </>
    );
}

export default App;
import "./Box.scss"

const Box = ({inputBox, outputBox, sentences}) => {
    return (
        <div id={`${inputBox ? "asr" : outputBox ? "translation" : ""}`} className={`box ${inputBox ? "box--input" : ""}`}>
            {sentences.length ? sentences.map((sentence, key) => <p key={key}>{sentence}</p>) : ''}
        </div>
    )
}

export default Box
import {createRoot} from "react-dom/client";
import './index.scss';
import App from './components/App';
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";

const domNode = document.getElementById("root")
const root = createRoot(domNode)

root.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18n}>
            <App/>
        </I18nextProvider>
    </BrowserRouter>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
